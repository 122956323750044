$font-family-sans-serif: 'Rubik', sans-serif;

@import url('https://fonts.googleapis.com/css?family=Rubik:300, 400,900');

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import 'variables.scss';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

@import './Login.scss';
@import './Dashboard.scss';

html {
  font-size: 0.8rem;

  @include media-breakpoint-up(sm) {
    font-size: 0.8rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 0.9rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  min-height: 100%;
}

.logo {
  color: #cfd8e4;
  font-size: 4rem;
  font-weight: 800;
  letter-spacing: -5px;
  margin-bottom: 0;

  span {
    display: block;
    margin-top: -1rem;
  }
}
