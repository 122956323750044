$font-family-base: $font-family-sans-serif;

$white: #f5f5f5;
$primary: #242582;

$body-color: #676a6c;
$body-bg: #f3f3f4;
$danger: #f64c72;

$border-radius: 0;

// Buttons
$input-btn-padding-y: 0.6rem;
$input-btn-padding-x: 1.2rem;
$input-btn-font-size: 0.8rem;

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size;
$btn-border-radius: 0;

// Forms

$label-margin-bottom: 0.2rem;

$input-padding-y: 0.75rem;
$input-padding-x: 0.7rem;
$input-font-size: 0.8rem;

$input-color: $body-color;
$input-box-shadow: none;
$input-border-radius: 0;
$input-focus-box-shadow: none;

$input-placeholder-color: rgba($body-color, 0.4);

$input-height: calc(
  #{$input-line-height * 1.25em} + #{$input-padding-y * 2} + #{$input-height-border}
);
