.login-container {
  max-width: 22rem;
}

.login-text {
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 0.8rem;
  color: rgba($danger, 0.8);
}

label {
  font-size: 0.75rem;
}
