$text-color: #adb5bd;

.dashboard-container {
  width: 40rem;
  max-width: 40rem;
}

.files {
  position: relative;
  &:before,
  &:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    right: 0;
    height: 40px;
    display: block;
    margin: 0 auto;
    color: $text-color;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
  }

  &:before {
    content: 'Upload Your PDF File';
    top: 60px;
    left: 0;
  }

  &:after {
    content: ' or drag it here. ';
    bottom: 10px;
    left: 0;
    font-size: 0.75rem;
  }

  input {
    outline: 2px dashed rgba($danger, 0.5);
    outline-offset: -15px;
    transition: all 0.15s ease-in-out;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;

    &:focus {
      outline: 2px dashed rgba($success, 0.5);
    }
  }
}

.conversion-tool {
  color: $text-color;
  outline: 2px dashed rgba($info, 0.5);
  outline-offset: -15px;
  width: 100%;
  background: white;
  text-align: center;
  padding: 3rem 0;
}

.upload-another {
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 0.8rem;
  color: rgba($danger, 0.8);
}
